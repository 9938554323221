import * as React from "react";
import { httpDelete, httpGet, httpPost, httpPut} from "../services/HttpService";
import { paths } from "../config/constant";


//Write API's functions below


export const insertLoginUser = (UserName: any, Password: any) => {
    return httpGet(paths.api.userLogin + "?UserName=" + UserName + "&Password=" + Password);
};

export const getAllSlider = () => {
    return httpGet(paths.api.getAllsliders);
};

export const getAllCurrentSlider = () => {
    return httpGet(paths.api.Get_current_Sliders);
};

export const getNewsLetter = () => {
    return httpGet(paths.api.getAllNewsLetters);
};

export const InsertOrUpdateSlider = (formdata:any) => {
    return httpPost(paths.api.insertOrUpdateSlider ,formdata);
};

export const insertOrUpdateNewsletter = (formdata:any) => {
    return httpPost(paths.api.insertOrUpdateNewsletter,formdata);
};

export const getCurrentNewsLetters = () => {
    return httpGet(paths.api.getCurrentNewsLetter);
};

export const fileUploading = (formdata:any) => {
    return httpPost(paths.api.fileUpload ,formdata);
};

export const dashboardCounts = (selectRange: any, fromDate: any,toDate:any) => {
    return httpGet(paths.api.getDashboardCounts + "?selectRange=" + selectRange + "&fromDate=" + fromDate + "&toDate=" + toDate);
};


export const insertOrUpdateContact = (formdata:any) => {
    return httpPost(paths.api.insertContact ,formdata);
};

export const downloadBrochure = (formdata:any) => {
    return httpPost(paths.api.brochureDownload ,formdata);
};






