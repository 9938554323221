const base = "/";
//let apiBase = "https://183.83.189.215:11356/api/"; //dev

 let apiBase = "https://agileapi.sysmedac.com/api/"; //dev

// let apiBase = "https://agileapi.sysmedac.com/api/"; //prod



if (process.env.NODE_ENV === "production") {
//apiBase ="https://183.83.189.215:11356/api/"; // dev

 apiBase = "https://agileapi.sysmedac.com/api/"; //dev
  
// apiBase = "https://agileapi.sysmedac.com/api/"; //prod
}

export const paths = {
  home                            : base,
  aboutus                         : base + "aboutus",
  services                        : base + "services",
  servicesHome                    : base + "servicesHome",
  servicesDesignEngg              : base + "services/designengg",
  servicesProcurement             : base + "services/procurement",
  servicesArchitecture            : base + "services/architectureDrawings",
  servicesProjectManagement       : base + "services/projectmanagement",
  servicesCivilConstruction       : base + "services/construction",
  servicesOMServices              : base + "services/omservices",
  servicesTraining                : base + "services/trainingdevelopment",
  products                        : base + "products",
  productandsparesHome            : base + "productshome",
  productsBalancePlantEquipment   : base + "products/projectmanagement",
  productsOEMSpares               : base + "products/construction",
  productsIndustrialParts         : base + "products/omservices",
  contacts                        : base + "contact",
  contactForAssociation           : base + "contact/association",
  contactForJobSeekers            : base + "contact/jobseekers",
  contactForEnquires              : base + "contact/enquires",
  associates                      : base + "associates",

  //adminLogin                       : base + 'admin',
  adminLogin                     : base + 'admin',
  ADMIN : {
    
    dashboard                      : base + 'admin/dashboard',
    newsletter                     : base + 'admin/newsletter',
    slider                         : base + 'admin/slider',
    contactus                      : base + 'admin/contactus',
  },

  api: {
    userLogin                       : apiBase + 'Agile/Login',
    insertOrUpdateSlider            : apiBase + 'Agile/InsertOrUpdateSlider',
    getAllsliders                   : apiBase + 'Agile/GetAllSliders',
    insertOrUpdateNewsletter        : apiBase + 'Agile/InsertOrUpdateNewsletter',
    getAllNewsLetters               : apiBase + 'Agile/GetAllNewsLetters',
    getCurrentNewsLetter            : apiBase + 'Agile/GetAllCurrentNewsLetters',
    fileUpload                      : apiBase + 'Agile/FileUpload',
    getDashboardCounts              : apiBase + 'Agile/GetDashboardCounts',
    insertContact                   : apiBase + 'Agile/InsertOrUpdateContactUs',
    brochureDownload                : apiBase + 'Agile/DownloadNotification',
    Get_current_Sliders             : apiBase + 'Agile/GetAllCurrentSliders'
  },
};
