import Agile_Logo from "../../assets/images/Agile_Logo.png";
import CallIcon from "@mui/icons-material/Call";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import { paths } from "../../config/constant";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Navigate, useLocation } from 'react-router-dom';
import {
  Logout,
  Person2Rounded,
  PersonOutlineOutlined,
} from '@mui/icons-material';

import 'bootstrap/js/src/collapse';
import 'bootstrap/js/dist/dropdown';
import { removeLocalStorage } from "../../services/AuthService";
import { useStateValue } from "../../providers/stateProvider";


function Header() {
  const Navigate = useNavigate();
  const [_drawer, _setDrawer] = useState(false);
  const pathName = window.location.pathname;
  const location = useLocation();
  const [index, setIndex] = useState(-1);
  const myFunction = () => {
    var x: any = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
      $("#modifiedServiceMenu").hide();
    }
  };


  // const headerActive = (pathName: any) => {
  //   if (pathName === "/") {
  //     $("#home").addClass("active");
  //   } else {
  //     $("#home").removeClass("active");
  //   }
  //   if (pathName === "/aboutus") {
  //     $("#about").addClass("active");
  //   } else {
  //     $("#about").removeClass("active");
  //   }
  //   if (
  //     pathName === "/services/designengg" ||
  //     pathName === "/services/procurement" ||
  //     pathName === "/services/projectmanagement" ||
  //     pathName === "/services/construction" ||
  //     pathName === "/services/omservices" ||
  //     pathName === "/services/trainingdevelopment"
  //   ) {
  //     $("#service").addClass(" active");
  //     if(pathName === "/services/designengg"){
  //       $("#service1").addClass(" active");
  //       $("#service11").addClass(" active");
  //     }
  //     else{
  //       $("#service1").removeClass(" active");
  //       $("#service11").removeClass(" active");
  //     }
  //     if(pathName === "/services/procurement"){
  //       $("#service2").addClass(" active");
  //       $("#service22").addClass(" active");
  //     }
  //     else{
  //       $("#service2").removeClass(" active");
  //       $("#service22").removeClass(" active");
  //     }
  //     if(pathName === "/services/projectmanagement"){
  //       $("#service3").addClass(" active");
  //       $("#service33").addClass(" active");
  //     }
  //     else{
  //       $("#service3").removeClass(" active");
  //       $("#service33").removeClass(" active");
  //     }
  //     if(pathName === "/services/construction"){
  //       $("#service4").addClass(" active");
  //       $("#service44").addClass(" active");
  //     }
  //     else{
  //       $("#service4").removeClass(" active");
  //       $("#service44").removeClass(" active");
  //     }
  //     if(pathName === "/services/omservices"){
  //       $("#service5").addClass(" active");
  //       $("#service55").addClass(" active");
  //     }
  //     else{
  //       $("#service5").removeClass(" active");
  //       $("#service55").removeClass(" active");
  //     }
  //     if(pathName === "/services/trainingdevelopment"){
  //       $("#service6").addClass(" active");
  //       $("#service66").addClass(" active");
  //     }
  //     else{
  //       $("#service6").removeClass(" active");
  //       $("#service66").removeClass(" active");
  //     }
  //   }
  //   else{
  //     $("#service").removeClass(" active");
  //     $("#service1").removeClass(" active");
  //     $("#service11").removeClass(" active");
  //     $("#service2").removeClass(" active");
  //     $("#service22").removeClass(" active");
  //     $("#service3").removeClass(" active");
  //     $("#service33").removeClass(" active");
  //     $("#service4").removeClass(" active");
  //     $("#service44").removeClass(" active");
  //     $("#service5").removeClass(" active");
  //     $("#service55").removeClass(" active");
  //     $("#service6").removeClass(" active");
  //     $("#service66").removeClass(" active");
  //   }

  //   if(pathName === "/products/projectmanagement" || pathName === "/products/construction" || pathName === "/products/omservices"){
  //     $("#product").addClass(" active");
  //     if(pathName === "/products/projectmanagement"){
  //       $("#product1").addClass(" active");
  //       $("#product11").addClass(" active");
  //     }
  //     else{
  //       $("#product1").removeClass(" active");
  //       $("#product11").removeClass(" active");
  //     }
  //     if(pathName === "/products/construction"){
  //       $("#product2").addClass(" active");
  //       $("#product22").addClass(" active");
  //     }
  //     else{
  //       $("#product2").removeClass(" active");
  //       $("#product22").removeClass(" active");
  //     }
  //     if(pathName === "/products/omservices"){
  //       $("#product3").addClass(" active");
  //       $("#product33").addClass(" active");
  //     }
  //     else{
  //       $("#product3").removeClass(" active");
  //       $("#product33").removeClass(" active");
  //     }
  //   }
  //   else{
  //     $("#product").removeClass(" active");
  //     $("#product1").removeClass(" active");
  //     $("#product11").removeClass(" active");
  //     $("#product2").removeClass(" active");
  //     $("#product22").removeClass(" active");
  //     $("#product3").removeClass(" active");
  //     $("#product33").removeClass(" active");
  //   }
  //   if(pathName === "/contact/association" || pathName === "/contact/jobseekers" || pathName === "/contact/enquires"){
  //     $("#contact").addClass(" active");
  //     if(pathName === "/contact/association"){
  //       $("#contact1").addClass(" active");
  //       $("#contact11").addClass(" active");
  //     }
  //     else{
  //       $("#contact1").removeClass(" active");
  //       $("#contact11").removeClass(" active");
  //     }
  //     if(pathName === "/contact/jobseekers"){
  //       $("#contact2").addClass(" active");
  //       $("#contact22").addClass(" active");
  //     }
  //     else{
  //       $("#contact2").removeClass(" active");
  //       $("#contact22").removeClass(" active");
  //     }
  //     if(pathName === "/contact/enquires"){
  //       $("#contact3").addClass(" active");
  //       $("#contact33").addClass(" active");
  //     }
  //     else{
  //       $("#contact3").removeClass(" active");
  //       $("#contact33").removeClass(" active");
  //     }
  //   }
  //   else {
  //     $("#contact").removeClass("active");
  //     $("#contact1").removeClass(" active");
  //     $("#contact11").removeClass(" active");
  //     $("#contact2").removeClass(" active");
  //     $("#contact22").removeClass(" active");
  //     $("#contact3").removeClass(" active");
  //     $("#contact33").removeClass(" active");
  //   }
  //   if (pathName === "/associates") {
  //     $("#associate").addClass("active");
  //   } else {
  //     $("#associate").removeClass("active");
  //   }
  // };

  // const headerNav = (e: any, value: any) => {
  //   e.preventDefault();
  //   if (value === "1") {
  //      $("#home").addClass("active");
  //     $("#myTopnav").removeClass("responsive");
  //     Navigate(paths.ADMIN.dashboard);
  //   } else {
  //     $("#home").removeClass("active");
  //   }
  //   if (value === "2") {
  //     $("#about").addClass("active");
  //     $("#myTopnav").removeClass("responsive");
  //     Navigate(paths.ADMIN.newsletter);
  //   } else {
  //     $("#about").removeClass("active");
  //   }
  //   // if (value === "3") {
  //   //   $("#serviceMenu").show();
  //   //   if (window.matchMedia("(max-width: 767px)").matches) {
  //   //     //$("#modifiedServiceMenu").removeClass("desk-Hide ");
  //   //     $("#modifiedServiceMenu").toggle();
  //   //   } else {
  //   //     $("#modifiedServiceMenu").addClass("desk-Hide ");
  //   //     //headerActive(pathName);
  //   //   }
  //   //   //Navigate(paths.services);
  //   // } else {
  //   //   $("#serviceMenu").hide();
  //   // }
  //   // if (value === "4") {
  //   //   $("#productMenu").show();
  //   //   if (window.matchMedia('(max-width: 767px)').matches) {
  //   //     $("#modifiedProductMenu").removeClass("desk-Hide ");
  //   //     $("#modifiedProductMenu").toggle();
  //   //   }
  //   //   else {
  //   //     $("#modifiedProductMenu").addClass("desk-Hide ");
  //   //     //headerActive(pathName);
  //   //   }
  //   // } else {
  //   //   $("#serviceMenu").hide();
  //   // }
  //   // if (value === "5") {
  //   //   $("#contactMenu").show();
  //   //   if (window.matchMedia('(max-width: 767px)').matches) {
  //   //     $("#modifiedContactMenu").removeClass("desk-Hide ");
  //   //     $("#modifiedContactMenu").toggle();
  //   //   }
  //   //   else {
  //   //     $("#modifiedContactMenu").addClass("desk-Hide ");
  //   //     //headerActive(pathName);
  //   //   }
  //   // } else {
  //   //   $("#serviceMenu").hide();
  //   // }
  //   if (value === "3") {
  //     $("#associate").addClass("active");
  //     $("#myTopnav").removeClass("responsive");
  //     Navigate(paths.ADMIN.slider);
  //   } else {
  //     $("#associate").removeClass("active");
  //   }
  //   if (value === "4") {
  //     $("#contactus").addClass("active");
  //     $("#myTopnav").removeClass("responsive");
  //     Navigate(paths.ADMIN.contactus);
  //   } else {
  //     $("#contactus").removeClass("active");
  //   }
  // };



  const headerNav = (value: any) => {
    debugger;
    switch (value) {
      case "1":
        Navigate(paths.ADMIN.dashboard);
        break;
      case "2":
        Navigate(paths.ADMIN.newsletter);
        break;
      case "3":
        Navigate(paths.ADMIN.slider);
        break;
      case "4":
        Navigate(paths.ADMIN.contactus);
        break;
      default:
        break;
    }
  };






  const mouseOver = (e: any, value: any) => {
    if (value === 'service') {
      $("#serviceMenu").show();
      $("#serviceMenu").addClass(" mob-Hide");
    }
    if (value === 'product') {
      $("#productMenu").show();
      $("#productMenu").addClass(" mob-Hide");
    }
    if (value === 'contact') {
      $("#contactMenu").show();
      $("#contactMenu").addClass(" mob-Hide");
    }
  }
  const mouseOut = () => {
    $("#serviceMenu").hide();
    $("#serviceMenu").removeClass(" mob-Hide");
  }
  const mouseOut1 = () => {
    $("#productMenu").hide();
    $("#productMenu").removeClass(" mob-Hide");
  }
  const mouseOut2 = () => {
    $("#contactMenu").hide();
    $("#contactMenu").removeClass(" mob-Hide");
  }
  const serviceSubMenu = (e: any, value: any) => {
    if (value === '1') {
      $('#myTopnav').removeClass("responsive");
      Navigate(paths.servicesDesignEngg);
    }
    if (value === "2") {
      $("#myTopnav").removeClass("responsive");
      Navigate(paths.servicesProcurement);
    }
    if (value === "3") {
      $("#myTopnav").removeClass("responsive");
      Navigate(paths.servicesProjectManagement);
    }
    if (value === "4") {
      $("#myTopnav").removeClass("responsive");
      Navigate(paths.servicesCivilConstruction);
    }
    if (value === "5") {
      $("#myTopnav").removeClass("responsive");
      Navigate(paths.servicesOMServices);
    }
    if (value === "6") {
      $("#myTopnav").removeClass("responsive");
      Navigate(paths.servicesTraining);
    }
  }
  const productSubMenu = (e: any, value: any) => {
    if (value === '1') {
      $('#myTopnav').removeClass("responsive");
      Navigate(paths.productsBalancePlantEquipment);
    }
    if (value === '2') {
      $('#myTopnav').removeClass("responsive");
      Navigate(paths.productsOEMSpares);
    }
    if (value === '3') {
      $('#myTopnav').removeClass("responsive");
      Navigate(paths.productsIndustrialParts);
    }
  }
  const contactSubMenu = (e: any, value: any) => {
    if (value === '1') {
      $('#myTopnav').removeClass("responsive");
      Navigate(paths.contactForAssociation);
    }
    if (value === '2') {
      $('#myTopnav').removeClass("responsive");
      Navigate(paths.contactForJobSeekers);
    }
    if (value === '3') {
      $('#myTopnav').removeClass("responsive");
      Navigate(paths.contactForEnquires);
    }
  }

  useEffect(() => {
    //headerActive(pathName);
    mouseOut();
    mouseOut1();
    mouseOut2();
    if (window.matchMedia('(max-width: 767px)').matches) {
      $("#modifiedServiceMenu").removeClass("desk-Hide ");
      $("#modifiedServiceMenu").hide();
      $("#modifiedProductMenu").removeClass("desk-Hide ");
      $("#modifiedProductMenu").hide();
      $("#modifiedContactMenu").removeClass("desk-Hide ");
      $("#modifiedContactMenu").hide();
    }
  });

  const setActiveIndex = (pathname: any) => {
    debugger
    switch (pathname) {
      case paths.ADMIN.dashboard:
        setIndex(0);
        break;
      case paths.ADMIN.newsletter:
        setIndex(1);
        break;
      case paths.ADMIN.slider:
        setIndex(2);
        break;
      case paths.ADMIN.contactus:
        setIndex(3);
        break;
      default:
        setIndex(-1);
        break;
    }
  };


  const logOutClick = () => {
    Navigate(paths.adminLogin);
}

  useEffect(() => {
    setActiveIndex(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="w-100" style={{ position: "fixed", zIndex: "999" }}>
        <div
          style={{ backgroundColor: "#DB6F09" }}
          className="col-md-12 text-end Head1"
        >
          <a
            className="call fs-14"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            href="tel:+919176353378"
          >
            <CallIcon className="fs-18" /> CALL : +91 91 76 35 33 78
          </a>
        </div>
        <div
          className="col-md-12 d-flex justify-content-between align-items-center px-4"
          style={{ background: "#f5f5f5", height: "4em" }}
        >
          <div className="col-md-2 text-center">
            <a onClick={() => headerNav("1")}>
              <img className="agileHeadLogo" src={Agile_Logo} alt="logo" />
            </a>
          </div>
          <div className="col-md-10">
            <IconButton className="dNone" onClick={() => _setDrawer(true)}><MenuIcon className="icon" /></IconButton>
            <div className="topnav" id="myTopnav">
              <li
                // className="home btn fw-bold"
                id="home"
                className={index === 0 ? "active home btn fw-bold" : "home btn fw-bold"}
                onClick={() => headerNav("1")}
              >
                Dashboard
              </li>

              <li
                //className="about btn fw-bold"
                id="about"
                className={index === 1 ? "active about btn fw-bold " : "about btn fw-bold"}
                onClick={() => headerNav("2")}
              >
                Brochure
              </li>

              {/* <li
                className="service btn"
                id="service"
                onMouseOver={(e: any) => mouseOver(e, 'service')}
                onMouseOut={() => mouseOut()}
                onClick={(e: any) => headerNav(e, "3")}
              >
                <div className="d-flex justify-content-between">Services <ArrowDropDownIcon className="desk-Hide" /></div>
                <ul className="serviceMenu" id="serviceMenu">
                  <li id="service1" onClick={() => Navigate(paths.servicesDesignEngg)} className="btn">Design Engineering & Detailing</li>
                  <li id="service2" onClick={() => Navigate(paths.servicesProcurement)} className="btn">Procurement, Expediting and Inspection</li>
                  <li id="service3" onClick={() => Navigate(paths.servicesProjectManagement)} className="btn">Project Management and Execution</li>
                  <li id="service4" onClick={() => Navigate(paths.servicesCivilConstruction)} className="btn">Civil and Total Construction</li>
                  <li id="service5" onClick={() => Navigate(paths.servicesOMServices)} className="btn">O&M and Speciality Services</li>
                  <li id="service6" onClick={() => Navigate(paths.servicesTraining)} className="btn">Training and Development</li>
                </ul>
              </li>
              <div id="modifiedServiceMenu" className="desk-Hide modifedMenu">
                <ul>
                  <li
                    id="service11"
                    onClick={(e: any) => serviceSubMenu(e, "1")}
                    className="btn"
                  >
                    Design Engineering & Detailing
                  </li>
                  <li
                    id="service22"
                    onClick={(e: any) => serviceSubMenu(e, "2")}
                    className="btn"
                  >
                    Procurement, Expediting and Inspection
                  </li>
                  <li
                    id="service33"
                    onClick={(e: any) => serviceSubMenu(e, "3")}
                    className="btn"
                  >
                    Project Management and Execution
                  </li>
                  <li
                    id="service44"
                    onClick={(e: any) => serviceSubMenu(e, "4")}
                    className="btn"
                  >
                    Civil and Total Construction
                  </li>
                  <li
                    id="service55"
                    onClick={(e: any) => serviceSubMenu(e, "5")}
                    className="btn"
                  >
                    O&M and Speciality Services
                  </li>
                  <li
                    id="service66"
                    onClick={(e: any) => serviceSubMenu(e, "6")}
                    className="btn"
                  >
                    Training and Development
                  </li>
                </ul>
              </div>
              <li
                className="product btn"
                id="product"
                onMouseOver={(e: any) => mouseOver(e, 'product')}
                onMouseOut={() => mouseOut1()}
                onClick={(e: any) => headerNav(e, "4")}
              >
                <div className="d-flex justify-content-between">Products & Spares <ArrowDropDownIcon className="desk-Hide" /></div>
                <ul onMouseOver={(e: any) => mouseOver(e, 'product')} className="serviceMenu" id="productMenu">
                  <li id="product1" onClick={() => Navigate(paths.productsBalancePlantEquipment)} className="btn">Balance of Plant Equipment</li>
                  <li id="product2" onClick={() => Navigate(paths.productsOEMSpares)} className="btn">OEM Spares and Customised Products</li>
                  <li id="product3" onClick={() => Navigate(paths.productsIndustrialParts)} className="btn">Industrial Hydraulic Parts</li>
                </ul>
              </li>
              <div id="modifiedProductMenu" className="desk-Hide modifedMenu">
                <ul>
                  <li id="product11" onClick={(e: any) => productSubMenu(e, '1')} className="btn">Balance of Plant Equipment</li>
                  <li id="product22" onClick={(e: any) => productSubMenu(e, '2')} className="btn">OEM Spares and Customised Products</li>
                  <li id="product33" onClick={(e: any) => productSubMenu(e, '3')} className="btn">Industrial Hydraulic Parts</li>
                </ul>
              </div>
              <li
                className="contact btn"
                id="contact"
                onMouseOver={(e: any) => mouseOver(e, 'contact')}
                onMouseOut={() => mouseOut2()}
                onClick={(e: any) => headerNav(e, "5")}
              >
                <div className="d-flex justify-content-between">Contact Us <ArrowDropDownIcon className="desk-Hide" /></div>
                <ul style={{ textAlign: "left" }} onMouseOver={(e: any) => mouseOver(e, 'contact')} className="serviceMenu" id="contactMenu">
                  <li id="contact1" onClick={() => Navigate(paths.contactForAssociation)} className="btn">For Association</li>
                  <li id="contact3" onClick={() => Navigate(paths.contactForEnquires)} className="btn">For Enquires</li>
                  <li id="contact2" onClick={() => Navigate(paths.contactForJobSeekers)} className="btn">For Careers</li>
                </ul>
              </li>
              <div id="modifiedContactMenu" className="desk-Hide modifedMenu">
                <ul>
                  <li id="contact11" onClick={(e: any) => contactSubMenu(e, '1')} className="btn">For Association</li>
                  <li id="contact33" onClick={(e: any) => contactSubMenu(e, '3')} className="btn">For Enquires</li>
                  <li id="contact44" onClick={(e: any) => contactSubMenu(e, '2')} className="btn">For Careers</li>
                </ul>

              </div> */}

              <li
                //className="associate btn fw-bold"
                className={index === 2 ? "active associate btn fw-bold" : "associate btn fw-bold"}
                id="associate"
                onClick={() => headerNav("3")}
              >
                Slider
              </li>
              {/* <li
                className={index === 3 ? "active contactus btn fw-bold" : "contactus btn fw-bold"}
                id="contactus"
                onClick={() => headerNav("4")}
              >
                Contact Us
              </li> */}
              <li className="logoutbtn  fw-bold" style={{listStyleType:"none",cursor:"pointer"}} id="logout" onClick={() => logOutClick()}>
                <span className="mx-2">Logout</span>
                <Logout className="muted me-3  text-primary"  />
              </li>
            </div>
          </div>
        </div>
      </div>
      <Drawer anchor={'right'} open={_drawer} onClose={() => _setDrawer(false)} PaperProps={{ sx: { width: "70%" } }} disableScrollLock={true} >
        <div className="p-1">
          <IconButton className="p-0 m-0" sx={{ mx: '4px', color: "red" }} onClick={() => _setDrawer(false)}>
            <CloseIcon />
          </IconButton>
          <div className="mt-3 sidenav">
            <NavLink className="text-decoration-none" to="/admin/dashboard" onClick={() => _setDrawer(false)} end>Dashboard</NavLink>
            <NavLink className="text-decoration-none" to={paths.ADMIN.newsletter} onClick={() => _setDrawer(false)}>Brochure</NavLink>

            {/* <button className="dropdownBtn btn d-inline-flex align-items-center justify-content-between collapsed" data-bs-toggle="collapse" data-bs-target="#services-collapse" aria-expanded="false">
              Services <i className="fa fa-caret-down text-right"></i>
            </button>
            <div className="collapse" id="services-collapse">
              <ul className="list-unstyled fw-normal pb-1 ps-2 small">
                <li><NavLink className="text-decoration-none" to={paths.servicesDesignEngg} onClick={() => _setDrawer(false)}>Design Engineering and Detailing</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.servicesProcurement} onClick={() => _setDrawer(false)}>Procurement, Expediting and Inspection</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.servicesProjectManagement} onClick={() => _setDrawer(false)}>Project Management and Execution</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.servicesCivilConstruction} onClick={() => _setDrawer(false)}>Civil and Total Construction</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.servicesOMServices} onClick={() => _setDrawer(false)}>O&M and Speciality Services</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.servicesTraining} onClick={() => _setDrawer(false)}>Training and Development</NavLink></li>
              </ul>
            </div>

            <button className="dropdownBtn btn d-inline-flex align-items-center justify-content-between collapsed" data-bs-toggle="collapse" data-bs-target="#product-collapse" aria-expanded="false">
              Product & Spares <i className="fa fa-caret-down text-right"></i>
            </button>
            <div className="collapse" id="product-collapse">
              <ul className="list-unstyled fw-normal pb-1 ps-2 small">
                <li><NavLink className="text-decoration-none" to={paths.productsBalancePlantEquipment} onClick={() => _setDrawer(false)}>Balance of Plant Equipment</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.productsOEMSpares} onClick={() => _setDrawer(false)}>OEM Spares and Customized Products</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.productsIndustrialParts} onClick={() => _setDrawer(false)}>Industrial Spare Parts & Accessories</NavLink></li>
              </ul>
            </div>

            <button className="dropdownBtn btn d-inline-flex align-items-center justify-content-between collapsed" data-bs-toggle="collapse" data-bs-target="#contact-collapse" aria-expanded="false">
              Contact Us <i className="fa fa-caret-down text-right"></i>
            </button>
            <div className="collapse" id="contact-collapse">
              <ul className="list-unstyled fw-normal pb-1 ps-2 small">
                <li><NavLink className="text-decoration-none" to={paths.contactForAssociation} onClick={() => _setDrawer(false)}>For Association</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.contactForEnquires} onClick={() => _setDrawer(false)}>For Enquiries</NavLink></li>
                <li><NavLink className="text-decoration-none" to={paths.contactForJobSeekers} onClick={() => _setDrawer(false)}>For Careers</NavLink></li>
              </ul>
            </div> */}

            <NavLink className="text-decoration-none" to={paths.ADMIN.slider} onClick={() => _setDrawer(false)}>Slider</NavLink>
            <NavLink className="text-decoration-none" to={paths.adminLogin} onClick={() => _setDrawer(false)}>Log Out</NavLink>
          </div>
        </div>
      </Drawer>
    </>
  );
}
export default Header;
